import HUI from './hui';

export default (() => {

    'use strict';

    function registerAlertClickHandler() {
        HUI.on(document.body, 'click', '[data-alert]', showAlert);
    }

    function showAlert(e) {
        e.preventDefault();
        e.stopPropagation();

        alert(this.dataset.alert);
    }

    document.addEventListener('turbolinks:load', registerAlertClickHandler, false);

})();