import intlTelInput from 'intl-tel-input/intlTelInputWithUtils';
import de from 'intl-tel-input/i18n/de';
import en from 'intl-tel-input/i18n/en';

const hiddenInput = (inputName) => ({phone: inputName})

document.addEventListener('turbolinks:load', () => {

    const inputFields = document.querySelectorAll('input[type="tel"]');
    const locale = document.documentElement.lang === 'de' ? de : en;

    const options = {
        autoPlaceholder: 'off',
        initialCountry: 'de',
        countryOrder: ['de', 'at', 'ch'],
        onlyCountries: ['al', 'ad', 'at', 'by', 'be', 'ba', 'bg', 'hr', 'cz', 'dk',
            'ee', 'fo', 'fi', 'fr', 'de', 'gi', 'gr', 'va', 'hu', 'is', 'ie', 'it', 'lv',
            'li', 'lt', 'lu', 'mk', 'mt', 'md', 'mc', 'me', 'nl', 'no', 'pl', 'pt', 'ro',
            'ru', 'sm', 'rs', 'sk', 'si', 'es', 'se', 'ch', 'ua', 'gb'],
        i18n: locale
    }

    inputFields.forEach((field) => {
        intlTelInput(field, field.dataset.fieldName ? {hiddenInput, ...options} : options);
    });

})

