export default (() => {

    function updateQuery() {

        const nodes = document.querySelectorAll('[data-update-query="true"]');

        for(let i = 0; i < nodes.length; i++) {

            const node = nodes[i];

            if(node.href === undefined) {
                continue;
            }

            const url = new URL(node.href);
            url.search = new URL(window.location.href).search;

            node.setAttribute('href', url.href);

        }
        
    }

    window.addEventListener('popstate', updateQuery, false);
    document.addEventListener('turbolinks:load', updateQuery, false);

})();