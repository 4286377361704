import HUI from './hui';

export default (() => {

    'use strict';

    function copyLinkToClipboard() {
        HUI.on(document.body, 'click', 'a[data-copy-link="to-clipboard"]', copy);
    }

    function copy(e) {

        e.preventDefault();
        e.stopPropagation();

        let input = document.createElement('input');
        input.setAttribute('type', 'text');
        input.setAttribute('value', this.href)

        this.parentElement.appendChild(input);
        input.select();

        document.execCommand('copy');
        this.parentElement.removeChild(input);

        alert('Link wurde in die Zwischenablage kopiert.')

    }

    document.addEventListener('turbolinks:load', copyLinkToClipboard, false);

})();