import HUI from './hui';

export default (() => {

    'use strict';

    function registerClickHandler() {
        HUI.on(document.body, 'click', '[data-quick-check]', quickCheck);
    }

    function quickCheck(e) {
        e.preventDefault();
        e.stopPropagation();

        const checkboxes = [...this.querySelectorAll('input[type="checkbox"]')];
        const checked = checkboxes.some(c => c.checked);

        for(const checkbox of checkboxes) {
            checkbox.checked = !checked;
        }
    }

    document.addEventListener('turbolinks:load', registerClickHandler, false);

})();