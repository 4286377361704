import HUI from './hui';

export default (() => {

    'use strict';

    const registerHandler = () => {
        HUI.on(document.body, 'click', '.deletable-attachment', deleteAttachment);
    }

    const deleteAttachment = async (e) => {
        e.preventDefault();
        e.stopPropagation();

        if(confirm('Wirklich löschen?')) {
            const id = e.target.dataset.signedId

            if(!id) { return }

            await fetch(`/attachments/${id}`, {
                method: 'DELETE',
                headers: {
                    'Accept': 'application/javascript',
                    'X-Requested-With': 'XMLHttpRequest',
                    'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]').content
                }
            })

            location.reload()
        }
    }

    document.addEventListener('turbolinks:load', registerHandler, false);

})();