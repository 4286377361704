const toggleCard = function(save) {
    const cardHeading = this.querySelector('.card-heading');
    const cardBody = this.querySelector('[data-accordion] + div');
    const cardFooter = cardBody.nextSibling;
    const arrowIcon = cardHeading.querySelector('.arrow-icon');

    const collapsed = cardBody.style.display === 'none'

    if (collapsed) {
        cardHeading.classList.add('border', 'bottom');
        cardBody.style.display = 'block';
        arrowIcon.style.transform = 'rotate(180deg)';

        if (cardFooter) {
            cardFooter.style.display = 'block';
        }
    } else {
        cardHeading.classList.remove('border', 'bottom');
        cardBody.style.display = 'none';
        arrowIcon.style.transform = 'rotate(0deg)';

        if (cardFooter) {
            cardFooter.style.display = 'none';
        }
    }

    if (this.id && save) {
        localStorage.setItem(`card-collapsed-${this.id}`, !collapsed);
    }
}

const initAccordion = () => {

    const cardHeadings = document.querySelectorAll('.card-heading.card-component');

    cardHeadings.forEach(function (cardHeading) {
        const accordionEnabled = cardHeading.hasAttribute('data-accordion');

        if (!accordionEnabled) { return }

        const card = cardHeading.closest('.card')
        const title = card.querySelector('.card-title')
        const cardBody = card.querySelector('[data-accordion] + div')

        const collapsed = cardBody.style.display === 'none'

        const storedState = localStorage.getItem(`card-collapsed-${card.id}`)
        const toggle = toggleCard.bind(card, true)

        if(card.id && storedState && collapsed !== (storedState === 'true')) {
            toggle(false)
        }

        title.addEventListener('click', toggle);
    });

}

document.addEventListener('turbolinks:load', initAccordion);
document.addEventListener('notes:updated', initAccordion);