document.addEventListener('turbolinks:before-visit', () => {
    const scrollSideNav = document.querySelector(".side-nav-menu.scrollable.ps")
    localStorage.setItem('scrollPosition', scrollSideNav.scrollTop.toString());
    localStorage.setItem('scrollHeight', scrollSideNav.scrollHeight.toString());
});


document.addEventListener('turbolinks:load', () => {
    const scrollSideNav = document.querySelector(".side-nav-menu.scrollable.ps")
    if (scrollSideNav) {
        const sideNav = document.querySelector(".side-nav-inner")
        sideNav.addEventListener('mouseenter', () => {
            const savedScrollPosition = localStorage.getItem('scrollPosition')
            const savedScrollHeight = localStorage.getItem('scrollHeight')
            if (savedScrollHeight !== null) {
                scrollSideNav.scrollHeight = Number(savedScrollHeight)
                localStorage.removeItem('scrollHeight')
            }
            if (savedScrollPosition !== null) {
                scrollSideNav.scrollTop = Number(savedScrollPosition)
                localStorage.removeItem('scrollPosition')
            }
        })
        scrollSideNav.addEventListener('scroll', () => {
            localStorage.setItem('scrollPosition', scrollSideNav.scrollTop.toString())
            localStorage.setItem('scrollHeight', scrollSideNav.scrollHeight.toString());
        })
    }
});