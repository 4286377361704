// noinspection JSVoidFunctionReturnValueUsed

import HUI from './hui';

export default (() => {

    function onClick() {

        const parentContainer = this.closest('table');

        if(!parentContainer) {
            return null;
        }

        const elements = document.querySelectorAll('td.checkbox-ids input.checkbox-toggle')

        elements.forEach((element) => {
            element.checked = this.checked
        })

    }

    document.addEventListener('turbolinks:load', () => {
        HUI.on(document.body, 'click', 'th.checkbox-ids input.checkbox-toggle', onClick);
    }, false);

})();