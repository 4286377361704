/* globals require */

const components = require.context('../components', true, /[_/]component\.js$/);
components.keys().forEach(path => {
    components(path);
})

import './scripts/links';
import './scripts/query_update';
import './scripts/passwords';
import './scripts/copy_to_clipboard';
import './scripts/checkbox';
import './scripts/alert';
import './scripts/quick_check';
import './scripts/confirm_unload';
import './scripts/form_utilities';
import './scripts/react';
import './scripts/attachment_deletion';
import './scripts/side_nav';
import './scripts/phone';
import './scripts/editor';
