export default (() => {

    'use strict';

    function registerConfirmUnloadHandler() {
        const nodes = document.querySelectorAll('form[data-confirm-unload="true"]');

        for(const node of nodes) {
            node.addEventListener('submit', () => {
                window.onbeforeunload = null;
            })
        }

        if (nodes.length > 0) {
            window.onbeforeunload = function (e) {
                return "Sie haben ungespeicherte Inhalte, wollen Sie die Seite wirklich verlassen?";
            };
        } else {
            window.onbeforeunload = null
        }
    }

    document.addEventListener('turbolinks:load', registerConfirmUnloadHandler, false);

})();