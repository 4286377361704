import Rails from '@rails/ujs'
import HUI from './hui';

export default (() => {

    function performDelete(e) {

        if(this.hasAttribute('data-remote')) {
            Rails.handleRemote.call(this, e);
        } else {
            Rails.handleMethod.call(this, e);
        }

    }

    function onClickDelete(e) {

        e.preventDefault();
        e.stopPropagation();

        if(this.dataset.confirmed) {

            performDelete.call(this, e);
            return;

        }

        let message;

        if(this.hasAttribute('data-message')) {
            message = this.dataset.message;
        } else {
            message = document.documentElement.lang === 'de' ? 'Wirklich löschen?' : 'Are you sure?';
        }

        if(confirm(message)) {
            performDelete.call(this, e);
        }

    }

    document.addEventListener('turbolinks:load', () => {
        HUI.on(document.body, 'click', 'a[data-method="delete"]', onClickDelete);
    }, false);

})();