/* globals global, require */

import { createRoot } from 'react-dom/client'
import React from 'react';

const reactComponents = {};

const context = require.context('components', true);
context.keys().forEach(key => reactComponents[key.match(/^\.\/([^.]+)/)[1]] = context(key).default)

class Reactor {

    roots = new Map()

    constructor() {
        document.addEventListener('turbolinks:load', this.initialize.bind(this), false)
    }

    initialize() {
        this.mountWithScope(document)
    }

    mountComponents(selector) {
        const scope = selector instanceof HTMLElement ? selector : document.querySelector(selector)

        this.mountWithScope(scope)
    }

    *iterateComponentNodes(scope) {
        const nodes = scope.querySelectorAll('[data-react-class]')

        for(const node of nodes) {
            yield node
        }
    }

    mountWithScope(scope) {
        for(const node of this.iterateComponentNodes(scope)) {
            if(this.roots.has(node)) { return this.roots.get(node) }

            const props = JSON.parse(node.dataset.reactProps)
            const Component = reactComponents[node.dataset.reactClass]

            const root = createRoot(node)

            this.roots.set(node, root)

            root.render(<Component {...props} />)
        }
    }

}

global.ReactRailsUJS = new Reactor()