export default (() => {

    document.addEventListener('turbolinks:load', () => {

        const elements = document.querySelectorAll('[data-password]')

        const iconTemplate = document.createElement('i');
        iconTemplate.style.cursor = 'pointer';
        iconTemplate.classList.add('fal');
        iconTemplate.classList.add('fa-lock');
        iconTemplate.classList.add('mr-2');
        iconTemplate.title = 'Passwort anzeigen';

        for(let i=0; i<elements.length; i++) {

            const element = elements[i];
            const icon = iconTemplate.cloneNode();

            element.style.position = 'relative';
            element.style.fontFamily = 'monospace';

            const tooltip = $(icon).tooltip();

            element.appendChild(icon);

            // Stars container
            const stars = document.createElement('span');
            stars.appendChild(document.createTextNode('\u2022'.repeat(element.dataset.password.length)));
            element.appendChild(stars);

            // Password container
            const password = document.createElement('span');
            password.style.display = 'none';
            password.appendChild(document.createTextNode(element.dataset.password));
            element.appendChild(password);

            icon.addEventListener('click', () => {

                if(icon.classList.contains('fa-lock')) {

                    stars.style.display = 'none';
                    password.style.display = 'inline-block';
                    icon.classList.replace('fa-lock', 'fa-unlock');
                    icon.title = 'Passwort ausblenden';

                } else {

                    stars.style.display = 'inline-block';
                    password.style.display = 'none';
                    icon.classList.replace('fa-unlock', 'fa-lock');
                    icon.title = 'Passwort anzeigen';
                }

                tooltip.tooltip('_fixTitle').tooltip('setContent');


            }, false);

        }

    }, false);

})();