import { highlightActiveLine, highlightActiveLineGutter, lineNumbers} from "@codemirror/view"
import { basicSetup, EditorView } from "codemirror";
import {html} from '@codemirror/lang-html';

document.addEventListener('turbolinks:load', () => {

    const editors = document.querySelectorAll('textarea[data-editor="code"]');

    editors.forEach(function(editor) {

        const view = new EditorView({
            doc: editor.value,
            extensions: [
                basicSetup,
                lineNumbers(),
                highlightActiveLine(),
                highlightActiveLineGutter(),
                html()
            ]
        });

        editor.parentNode.insertBefore(view.dom, editor);
        editor.style.display = 'none';

        if (editor.form) {
            editor.form.addEventListener('submit', () => {
                editor.value = view.state.doc.toString()
            })
        }

    });

});